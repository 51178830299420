import settings from 'configurations/application/config';
import application from 'services/application';

const baseUrl = settings.envVars.wikiUri;

const wiki = {
  baseUrl,
  categories: `${baseUrl}/categories`,
  categoryNude: `${baseUrl}/categories/#nude-categories`,
  categoryHotFlirt: `${baseUrl}/categories/#hot-flirt`,
  categorySoulMate: `${baseUrl}/categories/#soul-mate`,
  bankSEPA: `${baseUrl}/payout-system/#eur---sepa`,
  bankEUR: `${baseUrl}/payout-system/#eur---international-wire-transfer`,
  bankACH: `${baseUrl}/payout-system/#usd---ach`,
  holidayAwards: `${baseUrl}/holiday-awards`,
  payoutSystem: `${baseUrl}/payout-system`,
  payoutPandablue: `${baseUrl}/payout-system/#pandablue`,
  payoutEPayService: `${baseUrl}/payout-system/#PayoutSystem-epayservice`,
  payoutPaxum: `${baseUrl}/payout-system/#PayoutSystem-Paxum`,
  payoutPayoneer: `${baseUrl}/payout-system/#PayoutSystem-Payoneer`,
  payoutPaypal: `${baseUrl}/payout-system/#PayoutSystem-Paypal`,
  payoutSkrill: `${baseUrl}/payout-system/#PayoutSystem-Skrill`,
  payoutWebMoney: `${baseUrl}/payout-system/#webmoney`,
  payoutCosmo: `${baseUrl}/payout-system/#PayoutSystem-cosmo`,
  payoutTriplea: `${baseUrl}/payout-system/#crypto`,
  payoutPagomundo: `${baseUrl}/payout-system/#pagomundo`,
  tips: `${baseUrl}/tips-and-surprises`,
  stories: `${baseUrl}/mystory`,
  explicitVideos: `${baseUrl}/explicit-videos/`,
  explicitVideosConsent: `${baseUrl}/explicit-videos/#which-websites-will-promote-my-videos`,
  explicitVideosRequirements: `${baseUrl}/explicit-videos/#technical-requirements`,
  explicitVideosModelTagging: `${baseUrl}/explicit-videos/#model-tagging`,
  rules: `${baseUrl}/penalty-system`,
  referral: `${baseUrl}/model-referral`,
  automatedMessages: `${baseUrl}/livejasmin-messenger/#automated-messages`,
  faq: baseUrl,
  awards: `${baseUrl}/awards`,
  limitedStudioAccess: `${baseUrl}/new-model-center-limited-studio-access`,
  hideMyFaceOption: `${baseUrl}/hide-my-face-option`,
  promotionPeriod: `${baseUrl}/promotion-period`,
  technicalRequirements: `${baseUrl}/technical-requirements`,
  performance: `${baseUrl}/performance`,
  performanceEngagementScore: `${baseUrl}/performance/#engagement-score`,
  performanceConversionScore: `${baseUrl}/performance/#conversion-score`,
  studioAndSingleAccountRegistration: `${baseUrl}/studio-and-single-account-registration/#registration`,
  fanClub: `${baseUrl}/fan-club`,
};

if (application.Oranum) {
  wiki.bankSEPA = `${baseUrl}/payout-system/#sepa`;
  wiki.bankEUR = `${baseUrl}/payout-system/#international-wire-transfer`;
  wiki.bankACH = `${baseUrl}/payout-system/#ach`;
  wiki.payoutPandablue = `${baseUrl}/payout-system/#pandablue`;
  wiki.payoutPaxum = `${baseUrl}/payout-system/#paxum`;
  wiki.payoutPayoneer = `${baseUrl}/payout-system/#payoneer`;
  wiki.payoutPaypal = `${baseUrl}/payout-system/#paypal`;
  wiki.payoutWebMoney = `${baseUrl}/payout-system/#webmoney`;
  wiki.payoutCosmo = `${baseUrl}/payout-system/#cosmo`;
  wiki.rules = `${baseUrl}/rules`;
  wiki.stories = `${baseUrl}/story`;
  wiki.referral = `${baseUrl}/referral-program`;
  wiki.faq = `${baseUrl}/oranum-faqs`;
  wiki.awards = `${baseUrl}/awards`;
  wiki.limitedStudioAccess = `${baseUrl}/agency-center-limited-access`;
}

export default Object.freeze(wiki);
